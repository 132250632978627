import { graphql } from "gatsby"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button"
import Cards from "../components/Cards"
import Carousel from "../components/Carousel"
import Layout from "../layouts/Layout"
import firebase from "gatsby-plugin-firebase"

export default props => {
  const {
    description,
    gallery,
    name,
    related,
    summary,
    thumbnail,
    url,
    affiliate
  } = props.data.markdownRemark.frontmatter;

  let name_for_analytics = name;
  React.useEffect(() => {
    firebase
      .analytics()
      .logEvent( name_for_analytics )     
  }, [name_for_analytics])


  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail}
      />
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8">
            
              {gallery && gallery.length === 1 && (
                  <img
                  src={gallery[0]}
                  alt={name}
                />
              )}
              {gallery && gallery.length > 1 && <Carousel images={gallery} />}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {name}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                {summary}
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {url && (
                <div className="mt-8">
                  <Button href={url}>Next story >></Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {related && (
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container">
            <h2 className="text-3xl sm:text-4xl leading-tight font-extrabold tracking-tight text-gray-900 mb-8">
              You may also like
            </h2>
          </div>
          <Cards items={related} hideLastItemOnMobile={false} />
        </div>
      )}
      {affiliate && (
        <div className="bg-gray-100 py-12 lg:py-16">
          <div className="container">
            <h2 className="text-3xl sm:text-4xl leading-tight font-extrabold tracking-tight text-gray-900 mb-8">
              Pack your bag
            </h2>
          </div>
          <Cards items={affiliate} hideLastItemOnMobile={false} />
        </div>
      )}
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        description
        name
        summary
        url
        date
        thumbnail
        title
        gallery
        related {
          ...PortfolioCard
        }
        affiliate {
          ...AdCard
        }
      }
    }
  }
`;