import React from "react"
import Swiper from "react-id-swiper"

import "swiper/css/swiper.css"
import "./Carousel.css"

export const Carousel = ({ images }) => {
  const swiperParams = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  }
  return (
    <Swiper {...swiperParams}>
        {images.map((img, index) => {
        return (
          <div key={`slide_${index}`}>
           <img
            src={img}
            alt={`LostFalcon slide ${index}`}>

            </img>

          </div>
        )
      })}
    </Swiper>
  )
}

// // Carousel.propTypes = {
// //   images: PropTypes.arrayOf(PropTypes.object).isRequired,
// }

export default Carousel
